<template>
  <div>
    <roadmap-add-new
      :is-add-new-roadmap-sidebar-active.sync="isAddNewRoadmapSidebarActive"
      :product-id="productId"
    />
    <roadmap-item-add-new
      v-if="productData.projects"
      :is-add-new-roadmap-sidebar-active.sync="isAddNewRoadmapItemSidebarActive"
      :task-list="taskList"
      :roadmap-list="roadmapListForItem"
      :product-id="productId"
    />
    <section class="app-ecommerce-details">
      <!-- Alert: No item found -->
      <b-alert variant="danger" :show="productData === undefined">
        <h4 class="alert-heading">{{ $t("message.errorLoadingData") }}</h4>
        <div class="alert-body">
          {{ $t("message.errorProduct") }}
          <b-link class="alert-link" :to="{ name: 'apps-team-list' }">
            {{ $t("message.listOfProduct") }}
          </b-link>
          {{ $t("message.forOtherProduct") }}
        </div>
      </b-alert>

      <b-row class="match-height">
        <b-col xl="9">
          <b-card class="product-preview-card">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h2 class="invoice-logo">
                    {{ productData.name }}<br />

                    <b-badge
                      style="font-size: small !important"
                      :variant="badgeColor(productData.status)"
                    >
                      {{ getStatus(productData.status) }}
                    </b-badge>
                  </h2>
                </div>
              </div>

              <!-- Header: Right Content -->
              <div>
                <b-dropdown
                  id="dropdown-1"
                  class="float-right"
                  size="sm"
                  variant="link"
                  no-caret
                  v-if="
                    restrictions('button_edit_product') ||
                    restrictions('button_delete_product')
                  "
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="18"
                      class="cursor-pointer"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="
                      restrictions('button_edit_product') &&
                      productData.id != null
                    "
                    @click="
                      $router.push({
                        name: 'apps-product-edit',
                        params: { id: productData.id },
                      })
                    "
                    >{{ $t("message.buttons.edit") }}</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="restrictions('button_delete_product')"
                    v-b-modal.modal-delete-confirmation
                    >{{ $t("message.buttons.delete") }}</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </div>
            <br />
            <div>
              <b> {{ $t("message.products.desc") }} </b>
            </div>

            <b-card-text style="white-space: pre-line">
              <span v-html="productData.description"></span
            ></b-card-text>
          </b-card>
        </b-col>

        <b-col xl="3" md="4" sm="6">
          <b-card>
            <b class="card-text mb-25">{{
              $t("message.products.finEvolution")
            }}</b>
            <div id="chart">
              <vue-apex-charts
                type="bar"
                :height="stackedChart.height"
                :options="stackedChartOptions"
                :series="stackedSeries"
              ></vue-apex-charts>
            </div>
          </b-card>
        </b-col>

        <b-col xl="3" md="4" sm="6">
          <statistic-card-vertical
            hide-chart
            color="success"
            icon="AwardIcon"
            :statistic="currencyValue(viewData.result || 0)"
            :statistic-title="$t('message.products.result')"
          />
        </b-col>

        <b-col xl="3" md="4" sm="6">
          <statistic-card-vertical
            icon="DollarSignIcon"
            :statistic="currencyValue(viewData.allCost || 0)"
            :statistic-title="$t('message.products.totalCost')"
            color="danger"
          />
        </b-col>

        <b-col xl="3" md="4" sm="6">
          <statistic-card-vertical
            color="success"
            icon="ArchiveIcon"
            :statistic="currencyValue(viewData.allInvoicesValue || 0)"
            :statistic-title="$t('message.products.totalValue')"
          />
        </b-col>

        <b-col xl="3" md="4" sm="6">
          <statistic-card-vertical
            hide-chart
            color="danger"
            icon="MinusSquareIcon"
            :statistic="currencyValue(viewData.totalTax || 0)"
            :statistic-title="$t('message.products.totalTax')"
          />
        </b-col>

        <b-col xl="4" md="4" sm="6">
          <statistic-card-vertical
            hide-chart
            color="secondary"
            icon="FileTextIcon"
            :statistic="currencyValue(productData.initialValue || 0)"
            :statistic-title="$t('message.products.initialValue')"
          />
        </b-col>

        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            color="danger"
            icon="ClockIcon"
            :statistic="viewData.totalHour || 0"
            :statistic-title="$t('message.products.totalHours')"
          />
        </b-col>

        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            color="primary"
            icon="CheckSquareIcon"
            :statistic="viewData.taskQuantity || 0"
            :statistic-title="$t('message.products.totalTask')"
          />
        </b-col>

        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            color="secondary"
            icon="BriefcaseIcon"
            :statistic="viewData.customerQuantity || 0"
            :statistic-title="$t('message.products.totalCustomer')"
          />
        </b-col>

        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            hide-chart
            color="info"
            icon="TrendingUpIcon"
            :statistic="percentage(viewData.evolution || 0)"
            :statistic-title="$t('message.products.financialEvolution')"
          />
        </b-col>

        <b-col xl="3" md="4" sm="6">
          <statistic-card-vertical
            hide-chart
            color="success"
            icon="CalendarIcon"
            :statistic="localeDateList(viewData.oldProjectDate || 0)"
            :statistic-title="$t('message.products.birth')"
          />
        </b-col>

        <b-col xl="3" md="4" sm="6">
          <statistic-card-vertical
            hide-chart
            color="info"
            icon="PercentIcon"
            :statistic="percentage(viewData.percentage || 0)"
            :statistic-title="$t('message.products.projectEvolution')"
          />
        </b-col>

        <b-col xl="6" md="4" sm="6">
          <statistic-card-vertical
            hide-chart
            color="primary"
            icon="StarIcon"
            :statistic="
              calculateTimeBetweenDates(viewData.oldProjectDate || new Date())
            "
            :statistic-title="$t('message.products.lifeTime')"
          />
        </b-col>

        <b-col xl="12">
          <b-card class="card-revenue-budget">
            <b-row>
              <b-col md="12">
                <h4 v-if="productData.projects">
                  {{ $t("message.products.projects") }} ({{
                    productData.projects.length || 0
                  }})
                </h4>
                <a
                  v-for="(project, index) in productData.projects"
                  :key="project.id"
                >
                  <a
                    @click="openProjectbyId(project.id)"
                    style="color: #5ca6de"
                    >{{ project.name }}</a
                  >
                  <a v-if="productData.projects.length - 1 > index">, </a>
                </a>
              </b-col>
            </b-row>

            <hr class="invoice-spacing" />

            <b-row>
              <b-col md="12">
                <h4 v-if="productData.contracts">
                  {{ $t("message.products.contracts") }} ({{
                    productData.contracts.length || 0
                  }})
                </h4>
                <a
                  v-for="(contract, index) in productData.contracts"
                  :key="contract.id"
                >
                  <a
                    @click="openContractbyId(contract.id)"
                    style="color: #5ca6de"
                    >{{ contract.name }}</a
                  >
                  <a v-if="productData.contracts.length - 1 > index">, </a>
                </a>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col xl="12">
          <b-card>
            <div>
              <b> Roadmap      </b>
              <b-button @click="isAddNewRoadmapSidebarActive = true">{{
                $t("message.products.newRoadmap")
              }}</b-button>
              <b-button
                class="ml-2"
                @click="isAddNewRoadmapItemSidebarActive = true"
                v-if="roadmapList.length > 0"
                >{{ $t("message.products.newItem") }}</b-button
              >
            </div>
            <br />

            <div id="chart">
              <vue-apex-charts
                type="rangeBar"
                height="450"
                :options="chartOptionsRoadMap"
                :series="seriesRoadMap"
                @click="clickHandler"
              ></vue-apex-charts>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-modal
        id="modal-roadmap"
        modal-class="modal-primary"
        centered
        size="lg"
        title="Detalhes do roadmap"
        hide-footer
        no-close-on-backdrop
      >
        <b-row>
          <b-col md="11">
            <b-form-group label-for="nameProducts">
              <template v-slot:label>
                {{ $t("message.products.name") }}
              </template>
              <b-form-input
                v-model="selectRoadMapItem.name"
                label="name"
                label-for="nameProducts"
              />
            </b-form-group>
          </b-col>

          <b-col md="1">
            <b-form-group label="Active" label-for="userGroup">
              <template v-slot:label>
                {{ $t("message.user.active") }}
              </template>
              <b-form-checkbox
                style="margin-left: 7px; margin-top: 12px"
                v-model="selectRoadMapItem.status"
              >
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Descrição" label-for="descriptionTasks">
              <template v-slot:label>
                {{ $t("message.products.desc") }}
              </template>
              <b-alert variant="secondary" show>
                <div class="alert-body">
                  <span v-html="selectRoadMapItem.description"></span>
                </div>
              </b-alert>
            </b-form-group>
          </b-col>

          <b-col md="7">
            <b-form-group label-for="projectId">
              <template v-slot:label>
                {{ $t("message.products.tasks") }}
              </template>
              <v-select
                multiple
                v-model="selectRoadMapItem.tasks"
                :options="taskList"
                :reduce="(val) => val.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <b-col md="5">
            <b-form-group label-for="projectId">
              <template v-slot:label> Roadmap </template>
              <v-select
                v-model="selectRoadMapItem.roadmapId"
                :options="roadmapListForItem"
                :reduce="(val) => val.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label-for="dateExpenses">
              <template v-slot:label>
                {{ $t("message.products.initialDate") }}
              </template>
              <flat-pickr
                id="date-expenses"
                v-model="selectRoadMapItem.startDate"
                class="mt-0 form-control"
                :config="flatConfig"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label-for="dateExpenses">
              <template v-slot:label>
                {{ $t("message.products.finalDate") }}
              </template>
              <flat-pickr
                id="date-expenses"
                v-model="selectRoadMapItem.endDate"
                class="mt-0 form-control"
                :config="flatConfig"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <div class="text-right">
          <b-button
            v-b-modal.modal-roadmap
            variant="outline-danger"
            class="ml-1 mr-2"
            @click="deleteRoadmapItem()"
          >
            {{ $t("message.buttons.delete") }}
          </b-button>
          <b-button
            v-b-modal.modal-roadmap
            variant="primary"
            @click="updateRoadmapItem()"
          >
            {{ $t("message.buttons.save") }}
          </b-button>
        </div>
      </b-modal>

      <b-modal
        id="modal-delete-confirmation"
        modal-class="modal-danger"
        centered
        :title="$t('delete')"
        hide-footer
      >
        <b-card-text>
          {{ $t("message.deletAcount") }}
        </b-card-text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            :to="{ name: 'apps-product-cards' }"
            @click="deleteProduct()"
          >
            {{ $t("message.buttons.delete") }}
          </b-button>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BFormSpinbutton,
  BProgress,
  BProgressBar,
  BFormDatepicker,
  BCardBody,
  BTableLite,
  VBToggle,
  BDropdown,
  BDropdownItem,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import AccessControl from "@core/utils/access-control";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import RoadmapAddNew from "./RoadmapAddNew.vue";
import RoadmapItemAddNew from "./RoadmapItemAddNew.vue";
import store from "@/store";
import moment from "moment";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    BFormTextarea,
    BCardText,
    flatPickr,
    BFormSpinbutton,
    BProgress,
    BProgressBar,
    BFormDatepicker,
    BCardBody,
    BTableLite,
    VBToggle,
    BDropdown,
    BDropdownItem,
    BBadge,
    StatisticCardVertical,
    VueApexCharts,
    RoadmapAddNew,
    RoadmapItemAddNew,
  },

  data() {
    return {
      productData: [],
      //Select
      userData: store.state.user.userData,

      isAddNewRoadmapSidebarActive: false,
      isAddNewRoadmapItemSidebarActive: false,

      /////
      seriesRoadMap: [],
      chartOptionsRoadMap: {},
      viewData: [],
      selectRoadMapItem: [],

      /////
      roadmapList: [],
      roadmapListForItem: [],
      roadmapItemsList: [],

      /////
      stackedSeries: [],
      stackedChartOptions: {},
      stackedChart: {},

      taskList: [],

      flatConfig: { altFormat: "d/m/Y", altInput: true, format: "d/m/Y" },

      productId: router.currentRoute.params.id,
    };
  },

  mounted() {
    this.$root.$on("newListRoadmap", (newListRoadmap) => {
      this.roadmapList = [];
      this.reloadRoadmap();
    });
    this.$root.$on("newListRoadmapItem", (newListRoadmapItem) => {
      this.roadmapList = [];
      this.reloadRoadmap();
    });
  },

  watch: {
    locale(val) {
      this.$i18n.locale = val;
    },
  },

  async created() {
    await this.getData();

    await axios
      .get(`${URL_API}roadmap/${this.productId}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        this.roadmapList = response.data;
        for (var i = 0; i < response.data.length; i++) {
          this.roadmapListForItem.push({
            label: response.data[i].name,
            value: response.data[i].id,
          });
        }
      });

    await axios
      .get(`${URL_API}roadmap_items/list/${this.productId}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        this.roadmapItemsList = response.data;
      });

    await this.loadRoadmap();
    await this.loadTaskListByproject();

    await this.getViewData();
    await this.loadChart();
  },

  methods: {
    async loadTaskListByproject() {
      for (var i = 0; i < this.productData.projects.length; i++) {
        await axios
          .get(`${URL_API}task/product/${this.productData.projects[i].id}`, {
            headers: getHeader(this.userData),
          })
          .then((response) => {
            for (var i = 0; i < response.data.length; i++) {
              this.taskList.push({
                label: response.data[i].subject,
                value: response.data[i].id,
              });
            }
          });
      }
    },

    async loadChart() {
      var vm = this;

      this.stackedChart = {
        height: 350,
      };

      this.stackedSeries = [
        {
          name: this.getColLabel("revenue"),
          data: [this.viewData.allInvoicesValue],
        },
        {
          name: this.getColLabel("expenses"),
          data: [this.viewData.allCost],
        },
      ];

      this.stackedChartOptions = {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },

        xaxis: {
          categories: [
            vm.currencyValue(
              this.viewData.allInvoicesValue + this.viewData.allCost
            ),
          ],
        },

        yaxis: {
          show: false,
        },

        stroke: {
          width: 2,
          colors: ["#fff"],
        },

        colors: ["#00E396", "#e32500"],
        dataLabels: {
          style: {
            fontSize: "14px",
            fontFamily: "Arial",
            fontWeight: "bold",
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            borderWidth: 0,
          },
          enabled: true,
          formatter: function (val) {
            return vm.currencyValue(val);
          },
        },

        tooltip: {
          y: {
            formatter: function (val) {
              return vm.currencyValue(val);
            },
          },
        },
      };
    },

    getColLabel(colName) {
      return this.$i18n.t("message.products." + colName);
    },

    async reloadRoadmap() {
      this.roadmapList = [];
      this.roadmapItemsList = [];
      this.roadmapListForItem = [];
      await axios
        .get(`${URL_API}roadmap/${this.productId}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.roadmapList = response.data;
          for (var i = 0; i < response.data.length; i++) {
            this.roadmapListForItem.push({
              label: response.data[i].name,
              value: response.data[i].id,
            });
          }
          this.reloadRoadmapItems();
        });
    },

    async reloadRoadmapItems() {
      await axios
        .get(`${URL_API}roadmap_items/list/${this.productId}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.roadmapItemsList = response.data;
          this.loadRoadmap();
        });
    },

    updateRoadmapItem() {
      axios({
        method: "put",
        url: `${URL_API}roadmap_items/${this.selectRoadMapItem.id}`,
        headers: getHeader(this.userData),
        data: this.selectRoadMapItem,
      }).then(() => {
        this.$root.$emit("bv::hide::modal", "modal-roadmap");

        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updatedB"),
          },
        });

        this.reloadRoadmap();
      });
    },

    deleteRoadmapItem() {
      axios({
        method: "delete",
        url: `${URL_API}roadmap_items/${this.selectRoadMapItem.id}`,
        headers: getHeader(this.userData),
      }).then(() => {
        this.$root.$emit("bv::hide::modal", "modal-roadmap");

        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.deleted"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.deleted"),
          },
        });

        this.reloadRoadmap();
      });
    },

    restrictions(value) {
      return AccessControl(value);
    },

    openProjectbyId(id) {
      this.$router.push({ name: "apps-project-details", params: { id: id } });
    },

    openContractbyId(id) {
      this.$router.push({ name: "apps-contract-view", params: { id: id } });
    },

    openProjectbyId(projectId) {
      this.$router.push({
        name: "apps-projects-details",
        params: { id: projectId },
      });
    },

    currencyValue(value) {
      return this.$i18n.n(value, "currency");
    },

    badgeColor(value) {
      if (value === "Ativo") {
        return "success";
      }
      if (value === "Inativo") {
        return "danger";
      }
      return "primary";
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    async clickHandler(event, chartContext, config) {
      this.selectRoadMapItem = [];
      if (config.config.series[config.seriesIndex]) {
        await axios
          .get(
            `${URL_API}roadmap_items/${
              config.config.series[config.seriesIndex].data[
                config.dataPointIndex
              ].id
            }`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.selectRoadMapItem = response.data;
            this.$root.$emit("bv::show::modal", "modal-roadmap");
          });
      }
    },

    async loadRoadmap() {
      var vm = this;

      this.seriesRoadMap = [];

      if (this.roadmapItemsList.length != 0) {
        for (var i = 0; i < this.roadmapItemsList.length; i++) {
          for (var j = 0; j < this.roadmapList.length; j++) {
            if (this.roadmapItemsList[i].roadmapId === this.roadmapList[j].id) {
              this.seriesRoadMap.push({
                name: this.roadmapItemsList[i].name,
                data: [
                  {
                    x: this.roadmapList[j].name,
                    id: this.roadmapItemsList[i].id,
                    y: [
                      new Date(this.roadmapItemsList[i].startDate).getTime(),
                      new Date(this.roadmapItemsList[i].endDate).getTime(),
                    ],
                  },
                ],
              });
            }
          }
        }
      } else {
        for (var i = 0; i < this.roadmapList.length; i++) {
          this.seriesRoadMap.push({
            name: this.roadmapList[i].name,
            data: [
              {
                x: this.roadmapList[i].name,
                y: [null, null],
              },
            ],
          });
        }
      }

      this.chartOptionsRoadMap = {
        chart: {
          height: vm.getAutoHeight(vm.seriesRoadMap.length),
          type: "rangeBar",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "100%",
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontFamily: "Arial",
            fontWeight: "bold",
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            borderWidth: 0,
          },
          formatter: function (val, opts) {
            var label = opts.w.globals.initialSeries[opts.seriesIndex].name;
            var a = moment(val[0]);
            var b = moment(val[1]);
            var diff = b.diff(a, "days");
            return label + ": " + diff + (diff > 1 ? " dias" : " dia");
          },
        },
        xaxis: {
          type: "datetime",
        },
        stroke: {
          width: 1,
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.07,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100],
          },
        },
        legend: {
          position: "top",
        },

        tooltip: {
          shared: false,
          intersect: false,
          x: {
            show: true,
          },
          /* y: {
            formatter: (value, opts) => {
              return 'aaaa' + opts.w.globals.labels[opts.dataPointIndex];
            },          
          },*/
        },
      };
    },

    getAutoHeight(value) {
      if (value === 0) {
        return 350;
      } else if (value === 1) {
        return 2 * 70;
      } else {
        return value * 85;
      }
    },

    async getData() {
      await axios({
        method: "get",
        url: `${URL_API}product/details/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      })
        .then((response) => {
          this.productData = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.productData = undefined;
          }
        });
    },

    async getViewData() {
      await axios({
        method: "get",
        url: `${URL_API}chart/product/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        var string = response.data.chartInfo;
        this.viewData = JSON.parse(string)[0];
      });
    },

    calculateTimeBetweenDates(value) {
      if (value != "null") {
        var past = new Date(value);
      } else {
        var past = new Date();
      }

      var today = new Date();

      function calculate(today, past) {
        var result = getDateDifference(past, today);

        if (result && !isNaN(result.years)) {
          return (
            result.years +
            " ano" +
            (result.years == 1 ? " " : "s ") +
            result.months +
            " mes" +
            (result.months == 1 ? " " : "es ") +
            "e " +
            result.days +
            " dia" +
            (result.days == 1 ? "" : "s")
          );
        }
      }

      function getDateDifference(startDate, endDate) {
        if (startDate > endDate) {
          console.error("Start date must be before end date");
          return null;
        }
        var startYear = startDate.getFullYear();
        var startMonth = startDate.getMonth();
        var startDay = startDate.getDate();

        var endYear = endDate.getFullYear();
        var endMonth = endDate.getMonth();
        var endDay = endDate.getDate();

        var february =
          (endYear % 4 == 0 && endYear % 100 != 0) || endYear % 400 == 0
            ? 29
            : 28;
        var daysOfMonth = [
          31,
          february,
          31,
          30,
          31,
          30,
          31,
          31,
          30,
          31,
          30,
          31,
        ];

        var startDateNotPassedInEndYear =
          endMonth < startMonth ||
          (endMonth == startMonth && endDay < startDay);
        var years = endYear - startYear - (startDateNotPassedInEndYear ? 1 : 0);

        var months =
          (12 + endMonth - startMonth - (endDay < startDay ? 1 : 0)) % 12;

        // (12 + ...) % 12 makes sure index is always between 0 and 11
        var days =
          startDay <= endDay
            ? endDay - startDay
            : daysOfMonth[(12 + endMonth - 1) % 12] - startDay + endDay;

        return {
          years: years,
          months: months,
          days: days,
        };
      }

      var a = calculate(today, past);
      return a;
    },

    openProjectDetails(projectId) {
      this.$router.push({
        name: "apps-projects-details",
        params: { id: projectId },
      });
    },

    deleteProduct() {
      axios({
        method: "delete",
        url: `${URL_API}product/${this.productData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$i18n.t("message.toast.deleted"),
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            productData.value = undefined;
          }
        });
    },

    localeDate(value) {
      var newFormat = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "MM-DD-YYYY"
      );
      var newHour = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "HH:mm:ss"
      );

      if (newFormat != "Invalid date" && newFormat != null) {
        return (
          this.$i18n.d(
            new Date(newFormat.split("-")),
            "short",
            this.$i18n.locale
          ) +
          " - " +
          newHour
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    localeDateList(value) {
      if (value != 0 && value != "null") {
        if (value.day) {
          var day = value.day;
          var month = value.month;
          var year = value.year;

          var date = month + "-" + day + "-" + year;

          return this.$i18n.d(
            new Date(date.split("-")),
            "short",
            this.$i18n.locale
          );
        }
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    percentage(value) {
      return value.toFixed(1) + "%";
    },

    badgeColor(value) {
      if (value === true) {
        return "success";
      }
      return "danger";
    },

    getStatus(value) {
      if (value === true) {
        return this.getColLabel("active");
      }
      return this.getColLabel("notActive");
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

h5 {
  font-weight: normal;
}
</style>
